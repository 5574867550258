import React, { useState, useEffect } from 'react'
import { useEmblaCarousel } from 'embla-carousel/react'
import cx from 'classnames'

import { Image } from 'src/components/image'

import {
  LinkArrow
} from 'src/components/svgs'

export interface PressModuleProps {
  data: {
    title: string
    press: any[]
  }
}

export const PressModule = ({ data }: PressModuleProps) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true, center: true })
  const [active, setActive] = useState(0)
  useEffect(() => {
    if (emblaApi) {
      // Embla API is ready
      emblaApi.on('select', () => {
        setActive(emblaApi.selectedScrollSnap())
      })
    }
  }, [emblaApi])

  const {
    title,
    press
  } = data
  return (
    <div className='x p2--800'>
      <div className='container--xl tc outer mxa py2 mt4--800 p2--800 al p x'>
        <h2 className='italic saol'>{title}</h2>
        <div className='hide--800 mt2 press__viewport of--hidden bt bb' ref={emblaRef}>
          <div className='press__container df'>
            {press.map((item, i) => (
              <div className={cx('tc press__slide db p3 pr', {
                'active': i === active
              })} key={i}>
                <div className='mxa '>
                  <div dangerouslySetInnerHTML={{__html: item.svgLogo }} />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className='df jcb aic press__images show--800 mt4 ba'>
          {press.map((item, i) => (
            <button aria-label='select press quote' onClick={() => setActive(i)} className={cx('x nb no-style button press__button tc', {
              'active': i === active
            })} key={i}>
              <div className='press__icon p3 mxa'>
                <div dangerouslySetInnerHTML={{__html: item.svgLogo }} />
              </div>
            </button>
          ))}
        </div>
        <div className='container--800 mxa p2'>
          {press.map((item, i) => (
            <div className={cx('x press__texts mt3 tc', {
              'dn': i !== active
            })} key={i}>
              <span className='founders s28--800'>{item.quote}</span>
              {item.linkOut && (
                <div className='tc mt3'>
                  <a className='founders link-underline link-arrow caps' href={item.linkOut}>Read More <LinkArrow /></a>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default PressModule